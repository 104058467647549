import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRevenue from './revenue.reducer';
import { entityAdapter } from './revenue.reducer';

export const selectRevenueState = createFeatureSelector<fromRevenue.State>(
  fromRevenue.revenueFeatureKey
);

export const selectIsDataSourceLoading = createSelector(selectRevenueState, state => state.isLoadingDataSource);
export const selectIsDownloadInProgress = createSelector(selectRevenueState, state => state.isDownloadInProgress);
export const selectIsProgressBarVisible = createSelector(selectIsDataSourceLoading, selectIsDownloadInProgress,
  (isDataSourceLoading, isDownloadInProgress) => isDataSourceLoading || isDownloadInProgress
);
export const selectIsPaginatorDisabled = createSelector(selectIsProgressBarVisible, isProgressBarVisible => isProgressBarVisible);
export const selectPaginatorLength = createSelector(selectRevenueState, state => state.totalRowsCount);
export const selectPageIndex = createSelector(selectRevenueState, state => state.pagination.pageIndex);
export const selectPageSize = createSelector(selectRevenueState, state => state.pagination.pageSize);
export const selectDateFilter = createSelector(selectRevenueState, state => state.dateFilter);
export const selectPartnerCompanyAggregates = createSelector(selectRevenueState, state => state.partnerCompanyAggregates);
export const selectCompanyFilterOptions = createSelector(selectPartnerCompanyAggregates, partnerCompanyAggregates =>
  partnerCompanyAggregates
    ? partnerCompanyAggregates.map(agg => ({
      value: agg.id,
      display: agg.company.name
    })).sort((a, b) => a.display.localeCompare(b.display))
    : []
);
export const selectCompanyFilterValue = createSelector(selectRevenueState, state => state.companyFilterValue);
export const selectPagination = createSelector(selectRevenueState, state => state.pagination);
export const selectDataSourceParameters = createSelector(selectDateFilter, selectCompanyFilterValue, selectPagination, (dateFilter, companyFilterValue, pagination) =>
({
  startDate: dateFilter.startDate,
  endDate: dateFilter.endDate,
  companyId: companyFilterValue,
  pageSize: pagination.pageSize,
  pageIndex: pagination.pageIndex,
  previousPageIndex: pagination.previousPageIndex,
})
);

const { selectAll } = entityAdapter.getSelectors();
export const selectTableRows = createSelector(selectRevenueState, (state) => state.tableRows != null ? selectAll(state.tableRows) : null);
export const selectTableFooter = createSelector(selectRevenueState, (state) => state.footer);

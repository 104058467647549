import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { PartnerCompanyAggregate } from 'src/app/core/models/firestore.model';
import { DailyEarningsFooterRow } from 'src/app/daily-earnings/models/daily-earnings-footer-row';
import { DailyEarningsRow } from 'src/app/daily-earnings/models/daily-earnings-row';
import * as RevenueActions from './revenue.actions';

export const revenueFeatureKey = 'revenue';

export interface RevenueTableRow extends DailyEarningsRow {
  company: string;
}

export interface RevenueTableFooterRow extends DailyEarningsFooterRow {
  company: string;
}

export interface State {
  dateFilter: { startDate: string | null, endDate: string | null };
  partnerCompanyAggregates: PartnerCompanyAggregate[] | undefined;
  companyFilterValue: string;
  tableRows: EntityState<RevenueTableRow> | null;
  footer: RevenueTableFooterRow | null;
  isLoadingDataSource: boolean;
  isDownloadInProgress: boolean;
  totalRowsCount: number;
  pagination: {
    pageSize: number,
    pageIndex: number,
    previousPageIndex?: number
  };
}

export const entityAdapter: EntityAdapter<RevenueTableRow> =
  createEntityAdapter<RevenueTableRow>({});

export const initialState: State = {
  dateFilter: { startDate: null, endDate: null },
  tableRows: null,
  footer: null,
  isLoadingDataSource: false,
  isDownloadInProgress: false,
  totalRowsCount: 0,
  pagination: {
    pageSize: 25,
    pageIndex: 0,
    previousPageIndex: undefined
  },
  partnerCompanyAggregates: undefined,
  companyFilterValue: '',
};

export const reducer = createReducer(
  initialState,

  on(RevenueActions.revenueInit, (): State => ({
    ...initialState,
  })),

  on(RevenueActions.dateFilterChanged, (state, action): State => ({
    ...state,
    dateFilter: { startDate: action.startDate, endDate: action.endDate },
    pagination: {
      pageSize: state.pagination.pageSize,
      pageIndex: 0,
      previousPageIndex: undefined
    },
    isLoadingDataSource: true
  })),

  on(RevenueActions.dataChanged, (state, action): State => ({
    ...state,
    tableRows: entityAdapter.setAll(action.rows, state.tableRows || entityAdapter.getInitialState()),
    footer: action.footer,
    isLoadingDataSource: false,
    totalRowsCount: action.totalCount,
  })),

  on(RevenueActions.pageEvent, (state, action): State => ({
    ...state,
    isLoadingDataSource: true,
    pagination: {
      pageSize: action.pageSize,
      pageIndex: action.pageIndex,
      previousPageIndex: action.previousPageIndex
    }
  })),

  on(RevenueActions.companyFilterChanged, (state, action): State => ({
    ...state,
    companyFilterValue: action.companyId,
    pagination: {
      pageSize: state.pagination.pageSize,
      pageIndex: 0,
      previousPageIndex: undefined
    },
    isLoadingDataSource: true,
  })),

  on(RevenueActions.partnerCompanyAggregatesChanged, (state, action): State => ({
    ...state,
    partnerCompanyAggregates: action.partnerCompanyAggregates
  })),

  on(RevenueActions.rowExpandClicked, (state, action): State => {
    return {
      ...state,
      tableRows: entityAdapter.updateOne({
        id: action.row.id, changes: {
          isExpanded: !state.tableRows?.entities[action.row.id]?.isExpanded,
        }
      }, state.tableRows || entityAdapter.getInitialState())
    };
  }),

  on(RevenueActions.detailRowsFetched, (state, action): State => ({
    ...state,
    tableRows: entityAdapter.updateOne({
      id: action.rowId, changes: {
        detailRows: action.detailRows
      }
    }, state.tableRows || entityAdapter.getInitialState())
  })),
);

export const revenueFeature = createFeature({
  name: revenueFeatureKey,
  reducer,
});


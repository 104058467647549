import { createAction, props } from '@ngrx/store';
import { PartnerCompanyAggregate } from 'src/app/core/models/firestore.model';
import { DailyEarningsDetailRow } from 'src/app/daily-earnings/models/daily-earnings-detail-row';
import { DailyEarningsRow } from 'src/app/daily-earnings/models/daily-earnings-row';
import { RevenueTableFooterRow, RevenueTableRow } from './revenue.reducer';

export const revenueInit = createAction(
  '[Revenue] Revenue init'
);

export const revenueDestroy = createAction(
  '[Revenue] Revenue destroy'
);

export const dateFilterChanged = createAction(
  '[Revenue] Date filter changed',
  props<{ startDate: string | null, endDate: string | null }>()
);

export const dataChanged = createAction(
  '[Revenue] Data changed',
  props<{ rows: RevenueTableRow[], footer: RevenueTableFooterRow | null, totalCount: number; }>()
);

export const pageEvent = createAction(
  '[Revenue] Page event',
  props<{ pageSize: number; pageIndex: number; previousPageIndex?: number }>()
);

export const companyFilterChanged = createAction(
  '[Revenue] Company filter changed',
  props<{ companyId: string }>()
);

export const partnerCompanyAggregatesChanged = createAction(
  '[Revenue] Partner company aggregates changed',
  props<{ partnerCompanyAggregates: PartnerCompanyAggregate[] }>()
);

export const rowExpandClicked = createAction(
  '[Revenue] Row expand clicked',
  props<{ row: DailyEarningsRow }>()
);

export const detailRowsFetched = createAction(
  '[Revenue] Detail rows fetched',
  props<{ rowId: string, detailRows: DailyEarningsDetailRow[] }>()
);

export const downloadCsvClicked= createAction(
  '[Revenue] Download CSV clicked'
);

export const downloadSuccess = createAction(
  '[Revenue] Download CSV success'
);

export const downloadFailed = createAction(
  '[Revenue] Download CSV failed'
);

